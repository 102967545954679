$gray-2: #fafafa;
$gray-4: #f0f0f0;
$gray-5: #d9d9d9;
$gray-6: #bfbfbf;
$gray-7: #8c8c8c;
$gray-13: #000000;

$green-1: #f6ffed;
$green-2: #d9f7be;
$green-6: #52c41a;
$green-7: #389e0d;

$red-1: #fff1f0;
$red-4: #ff7875;
$red-6: #f5222d;

$blue-1: #e6f7ff;
$blue-2: #bae7ff;
$blue-3: #91d5ff;
$blue-4: #69c0ff;
$blue-5: #40a9ff;
$blue-6: #1890ff;

$geek-blue-1: #f0f5ff;
$geek-blue-3: #adc6ff;
$geek-blue-6: #2f54eb;

$volcano-2: #ffd8bf;
$volcano-6: #fa541c;

$purple-5: #9254de;
$purple-7: #531dab;

$yellow-5: #ffec3d;
$yellow-6: #fadb14;

$gold-5: #ffc53d;
$gold-6: #faad14;
