@use '../../../../styles/ant-colors.scss';
@use '../common.scss';

.service-danger-zone__header {
  @include common.zone-header;
  color: ant-colors.$red-6;
}

.service-danger-zone__button-container {
  @include common.zone-button-container;
}

.service-danger-zone__divider {
  margin-bottom: 18px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-bottom-width: 0;
}
