@use '../../../../styles/ant-colors.scss';
@use '../../../../styles/fonts.scss';
@use '../common.scss';

.service-view-zone__description {
  margin-top: 16px;
  margin-bottom: 16px;
}

.service-view-zone__lists-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-bottom: 16px;
}

.service-view-zone__button-container {
  @include common.zone-button-container;
}

.service-view-zone__list-container {
  flex: 1 1 50%;
}

.service-view-zone__list-header {
  display: block;
  font-size: 16px;
  margin-bottom: 16px;
}
