@use '../../../styles/fonts.scss';

.account-dropdown {
  font-family: fonts.$monospace-font-family;
  font-weight: 500 !important;
}

.account-menu-item {
  font-family: fonts.$monospace-font-family;
  font-weight: 400 !important;
}

.account-menu-item_selected {
  font-weight: 500 !important;
}
