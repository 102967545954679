@use "../../../styles/layout-variables.scss";

.accept-payments {
  display: flex;
  gap: 24px;
  flex-direction: row;
  height: calc(100% - (#{layout-variables.$view-title-height} + var(--grid-layout-gutter)));
}

.accept-payments__settings {
  width: 280px;
  flex: 0 0 auto;
}

.accept-payments__generator {
  width: 100%;
}
