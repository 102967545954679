@use './theme.scss';

@include theme.apply(light) {
    --global-primary-color: #0d61ff;
    --global-primary-light-color: #6d8eff;
    --global-primary-dark-color: #0038cb;

    --global-secondary-color: #f7f7ff;
    --global-secondary-light-color: #ffffff;
    --global-secondary-dark-color: #c4c4cc;

    --global-surface-color: #fff;
    --global-background-color: #fff;

    --global-on-primary-color: #fff;
    --global-on-secondary-color: #3c3c4c;
    --global-on-surface-color: #3c3c4c;
    --global-on-background-color: #3c3c4c;

    --global-shadow-top-color: rgba(0, 0, 0, 0.15);
    --global-shadow-bottom-color: rgba(0, 0, 0, 0.2);
}
