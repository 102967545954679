@use '../../styles/layout-variables.scss';

#root {
  height: 100%;
}

.main-container {
  display: grid;
  height: 100%;
  grid-template-columns: layout-variables.$navbar-width auto;
  grid-template-rows: layout-variables.$header-height calc(100% - #{layout-variables.$header-height});
  grid-template-areas:
    "header header"
    "nav main";
}

header {
  grid-area: header;
}

nav {
  grid-area: nav;
}

main {
  grid-area: main;
}
