@use '../../../../styles/ant-colors.scss';

.tag-active_active {
  background-color: ant-colors.$green-1 !important;
  color: ant-colors.$green-6 !important;
  border-color: ant-colors.$green-6 !important;
}

.tag-active_deleted {
  background-color: ant-colors.$red-1 !important;
  color: ant-colors.$red-6 !important;
  border-color: ant-colors.$red-6 !important;
}

.tag-active_updating {
  background-color: ant-colors.$blue-1 !important;
  color: ant-colors.$blue-6 !important;
  border-color: ant-colors.$blue-6 !important;
}
