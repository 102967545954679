@use '../../../styles/ant-colors.scss';
@use '../../../styles/fonts.scss';
@use './common.scss';

.service-container {
  margin: 0 auto;
  width: 100%;
}

.service-container_view {
  max-width: common.$container-width;
}

.service-button {
  width: 144px;
  flex-shrink: 0;
}
