@use '../../../styles/layout-variables.scss';

$-title-font-weight: 500;

.header {
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--grid-layout-margin);
  height: layout-variables.$header-height;
  background-color: var(--global-surface-color);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.header__title {
  color: var(--global-primary-color);
  font-weight: $-title-font-weight;
  font-size: 20px;
}
