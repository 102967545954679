@use '../../../../styles/ant-colors.scss';
@use '../../../../styles/fonts.scss';
@use './common.scss';

$-logo-size: 64px;

.service-card-container {
  @include common.clickable-card;
}

.service-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: common.$card-height;
  width: common.$card-width;
  padding: common.$card-padding;
}

.service-card__info-container {
  display: flex;
  flex-direction: column;
}

.service-card__main-info {
  display: flex;
  flex-direction: row;
  height: $-logo-size;
  margin-bottom: 14px;
}

.service-card__logo {
  height: $-logo-size;
  width: $-logo-size;
  margin: 0 8px 14px 0;
  flex-shrink: 0;
}

.service-card__logo_text {
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background-color: ant-colors.$volcano-2;
  color: ant-colors.$volcano-6;
  font-size: 20px;
  font-weight: 500;
}

.service-card__name-container {
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.service-card__name {
  font-weight: 500;
  font-size: 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 2px;
}

.service-card__link {
  color: ant-colors.$blue-6;
  font-family: fonts.$monospace-font-family;
}

.service-card__button-container {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.service-card__button {
  width: 135px;
}
