@use 'sass:math';
@use '../../../styles/layout-variables.scss';
@use '../../../styles/ant-colors.scss';

$-icon-size: 24px;

.service-link-editor {
  display: flex;
  align-items: center;
}

.service-link-editor__icon {
  font-size: $-icon-size;
  width: 30px;
  height: $-icon-size;
  margin-right: math.div(layout-variables.$element-spacing-small, 2);
  vertical-align: top;
}

.service-link-editor__input {
  color: ant-colors.$blue-6 !important;
}

.service-link-editor__delete-button {
  margin-left: 2px;
}
