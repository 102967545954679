@use '../../../../styles/ant-colors.scss';
@use '../common.scss';

.service-dev-zone__header {
  @include common.zone-header;
}

.service-dev-zone__button-container {
  @include common.zone-button-container;
}
