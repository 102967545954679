$container-width: 830px;

@mixin zone-header {
  display: block;
  font-size: 16px;
  margin-top: 64px;
  margin-bottom: 16px;
  color: black;
}

@mixin zone-button-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}
