.cards-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: var(--grid-layout-gutter);
}

.cards-container__small-card {
  grid-column-start: span 2;
  height: 220px;
  overflow: auto;

  .ant-card-head {
    border-bottom-width: 0;
  }
}

@media (max-width: 1000px) {
  .cards-container {
    grid-template-columns: 1fr;
  }

  .cards-container__small-card {
    grid-column-start: unset;
  }
}

$-charts-gap-size: var(--grid-layout-gutter);

.overview-analytics {
  display: flex;
  margin-top: $-charts-gap-size;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: var(--grid-layout-gutter);
}

.overview-analytics__chart {
  border-radius: 0;
  border-color: #f0f0f0;
  flex-basis: calc(50% - $-charts-gap-size/2);
  height: 500px !important;
  overflow: hidden;

  @media (max-width: 1100px) {
    flex-basis: 100%;
  }
}
