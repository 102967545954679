@use "../../../../styles/ant-colors.scss";

$-image-size: 16px;

.payment-amount {
  display: flex;
}

.payment-amount__select {
  width: 110px;
  flex: 0 0 auto;

  .ant-select-selector {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    background-color: ant-colors.$gray-2 !important;
    border-right-width: 0 !important;
  }
}

.payment-amount__input {
  width: 100% !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.payment-amount__select-option {
  display: flex;
  align-items: center;
  gap: 12px;
}

.payment-amount__select-option-image {
  height: $-image-size;
  width: $-image-size;
}

.payment-amount__select-option-label {
  display: inline-block;
  padding-top: 2px;
}
