.service-token-list-item {
  height: 32px;
  margin-top: 16px;

  .token-list-item__ticker,
  .token-list-item__name {
    font-size: 16px;
    color: var(--global-on-background-color);
  }
}
