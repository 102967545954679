@use '../../../styles/ant-colors.scss';
@use '../../../styles/fonts.scss';

.operation-list {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.operation-list-group {
  &:hover {
    background-color: ant-colors.$blue-1;
  }
}

.operation-list-item__icon,
.operation-list-item__main-info,
.operation-list-item__transfer-info,
.operation-list-item__amount,
.operation-list-item__data {
  padding: 16px 16px 0 16px;
}

.operation-list-item__data {
  padding-bottom: 16px;
}

.operation-list-item__data {
  padding-top: 6px;
  color: ant-colors.$gray-13;
}

.operation-list-item__icon,
.operation-list-item__transfer-info,
.operation-list-item__amount {
  vertical-align: top;
}

.operation-list-item__icon {
  padding-right: 0;
  width: 60px;
  vertical-align: top;
  padding-top: 19px;
}

.operation-list-item__transfer-info {
  font-family: fonts.$monospace-font-family;
  padding-top: 28px;
}

.operation-list-item__amount {
  padding-top: 24px;
  text-align: right;
  font-size: 20px;
}

.operation-list-item__amount_income {
  color: ant-colors.$green-6;
}

.operation-list-item__amount_expense {
  color: ant-colors.$gray-13;
}

.operation-list-item__amount_cancelled {
  color: ant-colors.$red-6;
}

.operation-list-item__date {
  font-size: 16px;
}

.operation-list-item__date,
.operation-list-item__operation-hash {
  display: block;
}

.operation-list-item__operation-hash {
  font-family: fonts.$monospace-font-family;
}

@media (max-width: 800px) {
  .operation-list {
    display: block;
  }

  .operation-list-group {
    display: block;
    margin-bottom: 16px;
  }

  .operation-list-row {
    display: grid;
    padding: 8px;
    grid-template-columns: 60px auto;
    grid-template-areas:
      "icon main"
      ". transfer"
      ". amount";
  }

  .operation-list-row_general {
    padding-bottom: 0;
  }

  .operation-list-row_order-data {
    padding-top: 0;
  }

  .operation-list-item__icon,
  .operation-list-item__main-info,
  .operation-list-item__transfer-info,
  .operation-list-item__amount,
  .operation-list-item__data {
    display: block;
    padding: 0;
  }

  .operation-list-item__icon {
    grid-area: icon;
    padding-top: 4px;
  }

  .operation-list-item__main-info {
    grid-area: main;
  }

  .operation-list-item__transfer-info {
    grid-area: transfer;
  }

  .operation-list-item__amount {
    grid-area: amount;
    text-align: unset;
    font-size: 16px;
  }
}
