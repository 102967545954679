@use 'sass:math';
@use '../../../styles/ant-colors.scss';

$-icon-container-size: 40px;
$-icon-size: 20px;
$-status-icon-size: 21px;

.operation-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $-icon-container-size;
  height: $-icon-container-size;
  border-radius: 50%;
  font-size: $-icon-size;
}

.operation-icon_income {
  background-color: ant-colors.$green-2;
  color: ant-colors.$green-7;
}

.operation-icon_expense {
  background-color: ant-colors.$gray-4;
  color: ant-colors.$gray-13;
}

.operation-icon_cancelled {
  background-color: ant-colors.$red-1;
  color: ant-colors.$red-4;
}

.operation-icon__status {
  position: absolute;
  font-size: $-status-icon-size;
  top: $-icon-container-size - math.div($-status-icon-size, 1.4);
  left: $-icon-container-size - math.div($-status-icon-size, 1.4);
}

.operation-icon__status_cancelled {
  color: ant-colors.$red-6 !important;
}
