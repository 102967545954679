@use "../../../styles/layout-variables.scss";

.view {
    padding: layout-variables.$element-spacing-small var(--grid-layout-gutter);
}

.view__title {
    margin: 0 0 var(--grid-layout-gutter) 0;
    height: layout-variables.$view-title-height;
}
