.generator__direct-link {
  margin: 0 auto;
  width: 100%;
  max-width: 580px;
  padding-top: 90px;
  padding-bottom: 16px;
}

.generator__direct-link-help-text {
  display: inline-block;
}

.generator__direct-link-link {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.generator__direct-link-buttons {
  margin-top: 16px;
  text-align: center;
}
